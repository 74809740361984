import React from "react"
import * as Chakra from "@chakra-ui/react"

export function ButtonLavender(props) {
	return (
		<Chakra.Button
			display="inline-flex"
			variant="unstyled"
			color="#fff"
			bgColor="#645389"
			pl="20px"
			pr="20px"
			fontSize="14px"
			fontWeight="bold"
			borderRadius="500px"
			minW="auto"
			paddingTop="3px"
			sx={{
				"&:hover": { backgroundColor: "#463a60" },
			}}
			{...props}
		/>
	)
}
