import React from "react"
import * as Chakra from "@chakra-ui/react"
import { Link } from "gatsby"

const NavbarLink = (props) => {
	return (
		<Chakra.Link fontSize="14px" fontWeight="bold" color="#645389" as={Link} {...props} />
	)
}

export default NavbarLink
