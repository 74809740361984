import React from 'react';
import * as Chakra from '@chakra-ui/react';

export function ButtonTransparent(props) {

    return (
        <Chakra.Button display="inline-flex" paddingTop="3px" variant="unstyled" border="1px solid #645389" color="#645389" pl="20px" pr="20px" fontSize="14px" fontWeight="bold" borderRadius="500px" minWidth="auto" sx={{
            "&:hover": { backgroundColor: "#64538911" },
            "&:focus": { backgroundColor: "#fff" },
        }} {...props}/>
    );
}
