import React from "react"
import * as Chakra from "@chakra-ui/react"

export function Input(props) {
	return (
		<Chakra.Input
			css={{
				"&::placeholder": { color: "#645389", transition: "0.3s" },
				"&:hover": { "&::placeholder": { color: "#645389cc" } },
			}}
			transition="0.3s"
			pl="20px"
			pr="20px"
			py="6px"
			variant="unstyled"
			border="1px solid #645389"
			fontWeight="600"
			fontSize="14px"
			sx={{
				"&:hover": { backgroundColor: "#64538911" },
				"&:focus": { backgroundColor: "#fff" },
			}}
			borderRadius="500px"
			paddingTop="9px"
			{...props}
		/>
	)
}
