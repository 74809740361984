import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  styles: {
    global: {
      html: {
        backgroundColor: "#645389",
      },
      body: {
        overflowY: "scroll",
        backgroundColor: "#645389",
        fontWeight: 500,
      },
      ".chakra-link": {
        textDecoration: "none!important",
        position: "relative",
        "&::after": {
          content: '""',
          position: "absolute",
          left: "0",
          height: "2px",
          width: "0",
          bottom: "-3px",
          backgroundColor: "#64538900",
          transition: "0.3s",
        },
        "&:hover::after": {
          width: "100%",
          backgroundColor: "#64538944",
        },
      },
    },
  },
  fonts: {
    heading: "Spartan",
    body: "Spartan",
  },
})

export default theme
