import React, {useState} from 'react';
import * as Chakra from '@chakra-ui/react';
import {Link} from "gatsby";

export function FooterLink(props) {

    return (
        <Chakra.Link fontWeight="bold" color="#645389" as={Link} fontSize="13px" lineHeight="20px" {...props} />
    );
}
