import React from "react"
import * as Chakra from "@chakra-ui/react"
import Logo from "../images/icons/sac_logo.svg"
import { Input } from "./input"
import { ButtonLavender } from "./button-lavender"
import { FooterLink } from "./footer-link"
import Email from "../images/icons/email.svg"
import Facebook from "../images/icons/facebook.svg"
import TikTok from "../images/icons/tiktok.svg"
import Instagram from "../images/icons/instagram.svg"
import LinkedIn from "../images/icons/linkedin.svg"
import Twitter from "../images/icons/twitter.svg"
import {graphql, useStaticQuery} from "gatsby";
import {Link} from "gatsby";
import SendInBlue from "./sendinblue"

const Subscribe = () => {
	const { isOpen, onOpen, onClose } = Chakra.useDisclosure()

	const [email, setEmail] = React.useState()

	return (
		<>
			<Chakra.VStack
				mt="40px"
				spacing="14px"
				width="100%"
				as="form"
				onSubmit={(e) => {
					e.preventDefault();
					onOpen()
				}}
			>
				<Chakra.Stack
					direction={{ base: "column", sm: "row" }}
					w="100%"
					spacing="15px"
				>
					<Chakra.FormControl width="100%" id="email" isRequired>
						<Input
							fontWeight="700"
							name="email"
							placeholder="Your Email"
							type="email"
							required
							autoComplete="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</Chakra.FormControl>
					<ButtonLavender type="submit" onClick={onOpen}>Subscribe</ButtonLavender>
				</Chakra.Stack>
			</Chakra.VStack>
			<Chakra.Modal isOpen={isOpen} onClose={onClose} isCentered>
				<Chakra.ModalOverlay />
				<Chakra.ModalContent
					p="0"
					borderRadius="42px"
					bg="#D7ECE7"
				>
					<Chakra.ModalCloseButton size="lg" color="#fff" top="-48px" right="0" />
					<SendInBlue email={email} closeModal={onClose} />
				</Chakra.ModalContent>
			</Chakra.Modal>
		</>
	)
}


const Footer = () => {

	let data = useStaticQuery(graphql`
		{
			wpMenu(locations: {eq: FOOTER_MENU}) {
				menuItems {
					nodes {
						url
						label
					}
				}
			}
		}
	`);

	let arrayLength = data.wpMenu.menuItems.nodes.length;
	let halfwayArrayLength = Math.round(arrayLength / 2);


	return (
		<>
			<Chakra.Flex
				bgColor="#E7E3EC"
				width="100%"
				justifyContent="center"
				py={{ base: "55px", lg: "80px" }}
			>
				<Chakra.Flex
					maxWidth="1170px"
					width="100%"
					justifyContent="space-between"
					flexDirection={{ base: "column", lg: "row" }}
				>
					<Chakra.VStack
						textAlign="left"
						alignItems="baseline"
						width={{ base: "100%", lg: "26.5%" }}
						px="30px"
						mb={{ base: "44px", lg: "0" }}
					>
						<Chakra.Box
							height={{ base: "52px", lg: "60px" }}
							as={Logo}
							mb={{ base: "40px", lg: "34px" }}
						/>
						<Chakra.Stack
							direction={{ base: "row", lg: "column" }}
							spacing="13.5px"
							width="100%"
							justifyContent="space-between"
						>
							<Chakra.VStack spacing="13.5px" alignItems="baseline" width="100%">
								{data.wpMenu.menuItems.nodes.slice(0,halfwayArrayLength).map((item, i) => (
									<FooterLink key={i} to={item.url}>{item.label}</FooterLink>
								))}
							</Chakra.VStack>
							<Chakra.VStack spacing="13.5px" alignItems="baseline" width="100%">
								{data.wpMenu.menuItems.nodes.slice(halfwayArrayLength, arrayLength).map((item, i) => (
									<FooterLink key={i} to={item.url}>{item.label}</FooterLink>
								))}
							</Chakra.VStack>
						</Chakra.Stack>
					</Chakra.VStack>

					<Chakra.VStack
						textAlign="left"
						alignItems="baseline"
						borderLeft={{ lg: "1px solid #B2A2C188" }}
						px={{ base: "30px", lg: "0" }}
						borderRight={{ lg: "1px solid #B2A2C188" }}
						width={{ base: "100%", lg: "47%" }}
						height="100%"
					>
						<Chakra.Flex
							borderTop={{ base: "1px solid #B2A2C188", lg: "none" }}
							borderBottom={{ base: "1px solid #B2A2C188", lg: "none" }}
							width="100%"
							height="100%"
						>
							<Chakra.Flex
								maxWidth={{ lg: "370px" }}
								flexDirection="column"
								justifyContent="center"
								m={{ lg: "auto" }}
								px={{ lg: "30px" }}
								py={{ base: "50px", lg: "0" }}
							>
								<Chakra.Heading
									textAlign={{ base: "left", lg: "center" }}
									w="100%"
									fontSize={{ base: "18px", lg: "20px" }}
									fontWeight="bold"
									lineHeight={{ base: "34px", lg: "38px" }}
									color="#645389"
									letterSpacing={{ base: "-0.16px", lg: "-0.18px" }}
									mb="15px"
								>
									Be part of the crowd.
								</Chakra.Heading>
								<Chakra.Text
									textAlign={{ base: "left", lg: "center" }}
									w="100%"
									fontSize="13px"
									color="#3F3459"
									lineHeight="26px"
									letterSpacing="-0.1px"
									fontWeight="600"
								>
									Sign up for updates, news, and ways you can help to end gender
									based violence.
								</Chakra.Text>
								<Subscribe />
							</Chakra.Flex>
						</Chakra.Flex>
					</Chakra.VStack>

					<Chakra.VStack
						textAlign="left"
						alignItems="baseline"
						width={{ base: "100%", lg: "26.5%" }}
						px="30px"
					>
						<Chakra.Flex flexDirection={{base: "column-reverse", lg: "column"}}>
							<Chakra.Box mt={{base: "30px", lg: "50px"}}>
								<FooterLink to="/privacy">
									Privacy Policy
								</FooterLink>
							</Chakra.Box>
							<Chakra.Text
								mt={{base: "30px", lg: "60px"}}
								textAlign="left"
								w="100%"
								fontSize="13px"
								color="#3F3459"
								lineHeight="26px"
								letterSpacing="-0.1px"
							>
								Sometimes telling your story can be triggering. If you feel like
								chatting to someone or need to seek help, please contact 1800
								RESPECT. If you’re in danger contact 000 or 112.
							</Chakra.Text>
							<Chakra.Flex
								mt="40px"
								color="#3F3459"
								width="calc(100% - 20px)"
								justifyContent={{base: "end", lg: "space-between"}}
								css={{
									"svg": {
										marginRight: "20px",
									}
								}}
							>
								<Link to="https://twitter.com/shesacrowd">
								<Twitter />
								</Link>
								<Link to="https://www.facebook.com/shesacrowd/">
								<Facebook />
								</Link >
								<Link to="https://www.linkedin.com/company/shesacrowd/">
								<LinkedIn />
								</Link>
								<Link to="https://www.instagram.com/shesacrowd/">
								<Instagram />
								</Link >
								<Link to="https://www.tiktok.com/@shesacrowd?">
								<TikTok />
								</Link>
								<Link to="/contact-us">
								<Email />
								</Link>
							</Chakra.Flex>
						</Chakra.Flex>
					</Chakra.VStack>
				</Chakra.Flex>
			</Chakra.Flex>
			<Chakra.Flex
				bgColor="#645389"
				width="100%"
				justifyContent="center"
				py={{ base: "35px", lg: "40px" }}
			>
				<Chakra.Flex
					maxWidth="1170px"
					width="100%"
					justifyContent="space-between"
					flexDirection={{ base: "column-reverse", lg: "row" }}
				>
					<Chakra.VStack
						textAlign="left"
						alignItems="baseline"
						width={{ base: "100%", lg: "26.5%" }}
						px="30px"
					>
						<Chakra.Text color="#fff" lineHeight="22px" fontSize="11px" letterSpacing="-0.09px">© 2023 She’s A Crowd. <br/>All rights reserved.</Chakra.Text>
					</Chakra.VStack>

					<Chakra.VStack
						textAlign="left"
						alignItems="baseline"
						width={{ base: "100%", lg: "73.5%" }}
						height="100%"
						px="30px"
						mb={{ base: "24px", lg: "0" }}
					>
							<Chakra.Flex
								flexDirection="column"
							>
								<Chakra.Text pl={{lg: "50px"}} color="#fff" lineHeight="22px" fontSize="11px" letterSpacing="-0.09px">We work on the traditional land of the Kulin and Gadigal peoples. We acknowledge that sovereignty was never ceded. We call for a treaty and we pay our deepest respect to elders past, present and emerging.</Chakra.Text>
							</Chakra.Flex>
					</Chakra.VStack>


				</Chakra.Flex>
			</Chakra.Flex>
		</>
	)
}

export default Footer
