import React from 'react';
import * as Chakra from '@chakra-ui/react';

export function ButtonMint(props) {

    return (
        <Chakra.Button display="inline-flex" pt="3px" variant="unstyled" backgroundColor="#A2D3C7" color="#645389" pl="20px" pr="20px" fontSize="14px" fontWeight="bold" borderRadius="500px" minWidth="auto" sx={{
            "&:hover": { backgroundColor: "#d1e9e3" },
        }} {...props} />
    );
}
