import React from "react"
import * as Chakra from "@chakra-ui/react"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import "./style.css"


export default function Layout({ children, pageContext }) {
	let navBg = "transparent"

	if (pageContext.layout === "contact-us") {
		navBg = "#E7E3EC"
	}

	return (
		<>
			<style dangerouslySetInnerHTML={{__html: "\n  #sib-container input:-ms-input-placeholder {\n    text-align: left;\n    color: #3f3459;\n  }\n\n  #sib-container input::placeholder {\n    text-align: left;\n    color: #3f3459;\n  }\n\n  #sib-container textarea::placeholder {\n    text-align: left;\n    color: #3f3459;\n  }\n" }} />
			<link rel="stylesheet" href="https://sibforms.com/forms/end-form/build/sib-styles.css" />
			<Chakra.Flex minH="100vh" flexDirection="column" bg="#fff" paddingTop="125px">
				<Navbar bg={navBg} />
				{children}
				<Footer />
			</Chakra.Flex>
		</>
	)
}
