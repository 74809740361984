import React from 'react';
import * as Chakra from '@chakra-ui/react';
import parse from 'html-react-parser';

export function BodyText({dangerouslySetInnerHTML, children, ...props}) {

    if (dangerouslySetInnerHTML && dangerouslySetInnerHTML.__html) {
        try {
            children = parse(dangerouslySetInnerHTML.__html)
        } catch (e) {
            console.log(e);
            console.log(dangerouslySetInnerHTML)
        }

    }

    return (
        <Chakra.Text css={{a: { textDecoration: "underline" }}} textAlign="left" w="100%" fontSize="13px" color="#3F3459" lineHeight="26px" letterSpacing="-0.1px" {...props}>
            {children}
        </Chakra.Text>
    );
}
