import React from "react"
import * as Chakra from "@chakra-ui/react"
import Logo from "../images/icons/sac_logo.svg"
import LogoWhite from "../images/icons/sac_logo_white.svg"
import useScrollPosition from "@react-hook/window-scroll"
import { ButtonTransparent } from "./button-transparent"
import { ButtonMint } from "./button-mint"
import Hamburger from "../images/icons/hamburger.svg"
import NavbarLink from "./navbar-link"
import { graphql, useStaticQuery, Link } from "gatsby"
import FadeIn from "react-fade-in"
import Instagram from "../images/icons/instagram.svg"
import LinkedIn from "../images/icons/linkedin.svg"
import Facebook from "../images/icons/facebook.svg"
import TikTok from "../images/icons/tiktok-white.svg"
import Twitter from "../images/icons/twitter.svg"
import Email from "../images/icons/email.svg"
import { BodyText } from "./body-text"
import Exit from "../images/icons/exit.svg"
import {wordpressFlatMenuToHierarchical} from "../utils/wordpress-flat-menu-to-hierarchical";
import debounce from 'debounce'

const Navbar = (props) => {
  const { isOpen, onOpen, onClose } = Chakra.useDisclosure()

  const scrollY = useScrollPosition(40)

  let data = useStaticQuery(graphql`
    {
      wpMenu(locations: { eq: PRIMARY_NAVIGATION }) {
        menuItems {
          nodes {
            parentId
            label
            id
            url
          }
        }
      }
    }
  `)

  const parsedHierarchical = React.useMemo(() => wordpressFlatMenuToHierarchical(data?.wpMenu?.menuItems?.nodes), [])

  return (
    <>
      <Chakra.Flex
        {...props}
        className={scrollY > 20 ? "scrolled" : undefined}
        css={{
          backdropFilter: "blur(2px)",
          "&.scrolled": {
            backgroundColor: "#E7E3EC",
            "@media only screen and (max-width: 48em)": {
              height: "70px",
              maxHeight: "70px",
            },
          },
        }}
        position="fixed"
        width="100%"
        top="0"
        backgroundColor={!isOpen ? "transparent" : "#3F3459!important"}
        shadow={scrollY > 20 && !isOpen ? "base" : undefined}
        transition="0.3s"
        flexGrow="1"
        height="125px"
        maxHeight="125px"
        zIndex={isOpen ? 41 : 39}
      >
        <Chakra.Flex
          px="30px"
          py={{ base: "20px", lg: "29px" }}
          height={{ base: "92px", lg: "125px" }}
          maxWidth="1230px"
          width="100%"
          mx="auto"
          justifyContent="space-between"
          alignSelf="center"
          alignItems="center"
        >
          <Link to="/">
            {!isOpen && (
              <Chakra.Box as={Logo} height={{ base: "52px", lg: "60px" }} />
            )}
            {isOpen && (
              <Chakra.Box
                as={LogoWhite}
                height={{ base: "52px", lg: "60px" }}
              />
            )}
          </Link>
          <Chakra.HStack
            spacing={{ base: "27px", xl: "54px" }}
            d={{ base: "none", lg: "flex" }}
          >
            {parsedHierarchical.map((item, i) => (
                <MenuItem {...item} key={i}/>
            ))}
          </Chakra.HStack>
          <Chakra.HStack spacing={{ base: "30px", lg: "20px" }}>
            <ButtonTransparent
              d={{ base: "none", lg: "flex" }}
              as="a"
              href="https://share.shesacrowd.com/map"
            >
              Share your story
            </ButtonTransparent>
            <ButtonMint as="a" href="https://www.accuweather.com/en/world-weather">
              Quick exit
            </ButtonMint>
            <Chakra.Box
              onClick={isOpen ? onClose : onOpen}
              as="button"
              d={{ base: "block", lg: "none" }}
            >
              {isOpen ? <Exit /> : <Hamburger />}
            </Chakra.Box>
          </Chakra.HStack>
        </Chakra.Flex>
        <Chakra.Drawer
          placement="left"
          size="full"
          onClose={onClose}
          isOpen={isOpen}
        >
          <Chakra.DrawerOverlay>
            <Chakra.DrawerContent>
              <Chakra.DrawerBody
                padding="0"
                as={Chakra.Stack}
                spacing="35px"
                flexDir="column"
                d="flex"
                justifyContent="space-between"
                alignItems="baseline"
                backgroundColor="#3F3459"
                height="100%"
              >
                <Chakra.Flex px="30px" pt="175px" w="100%" flexShrink={0}>
                  <Chakra.Box w="100%" flexShrink={0}>
                    <FadeIn>
                      {/* mobile menu */}
                      {parsedHierarchical.map((item, i) => (
                        <Chakra.Box py="14px" key={i}>
                          {
                            item.childMenu.length >= 1 ? (
                              <Chakra.Accordion allowToggle w="100%" flexShrink={0}>
                                <Chakra.AccordionItem border="none" w="100%">
                                  <Chakra.AccordionButton padding="0" color="white" _hover={{ background: "transparent" }} _focus={{ outline: "none" }}>
                                    <Chakra.Text 
                                      fontSize="14px"
                                      lineHeight="24px"
                                      letterSpacing="-0.12px"
                                      flex="1" 
                                      textAlign="left" 
                                      color="#FFFFFF"
                                      fontWeight="bold"
                                      pr="40px"
                                    >
                                      {item.label}
                                    </Chakra.Text> 
                                    <Chakra.AccordionIcon />
                                  </Chakra.AccordionButton>
                                  <Chakra.AccordionPanel pb="0" display="flex" flexDirection="column" w="100%">
                                    {
                                      item.childMenu.map((subItem, i) => (
                                        <NavbarLink
                                          onClick={onClose}
                                          fontSize="14px"
                                          lineHeight="20px"
                                          letterSpacing="-0.12px"
                                          color="#fff"
                                          to={subItem.url}
                                          key={i+1}
                                          pb="10px"
                                          w="100%"
                                        >
                                          {subItem.label}
                                        </NavbarLink>
                                      ))
                                    }
                                  </Chakra.AccordionPanel>
                                </Chakra.AccordionItem>
                              </Chakra.Accordion>
                            ) : (
                              <NavbarLink
                                onClick={onClose}
                                fontSize="14px"
                                lineHeight="20px"
                                letterSpacing="-0.12px"
                                color="#fff"
                                to={item.url}
                              >
                                {item.label}
                              </NavbarLink> 
                            )
                          }
                          
                        </Chakra.Box>
                      ))}
                    </FadeIn>
                  </Chakra.Box>
                </Chakra.Flex>
                <Chakra.Flex px="30px" direction="column">
                  <Chakra.Flex
                    mt="40px"
                    color="#fff"
                    width="calc(100% - 20px)"
                    justifyContent={{ base: "end", md: "space-between" }}
                    css={{ svg: { marginRight: "20px" } }}
                    pb="30px"
                  >
                    <Link to="https://twitter.com/shesacrowd">
                    <Twitter />
                    </Link>
                    <Link to="https://www.facebook.com/shesacrowd/">
                    <Facebook />
                    </Link >
                    <Link to="https://www.linkedin.com/company/shesacrowd/">
                    <LinkedIn />
                    </Link>
                    <Link to="https://www.instagram.com/shesacrowd/">
                    <Instagram />
                    </Link >
                    <Link to="https://www.tiktok.com/@shesacrowd?">
                    <TikTok />
                    </Link>
                    <Link to="/contact-us">
                    <Email />
                    </Link>
                  </Chakra.Flex>
                  <BodyText color="#fff" pb="30px">
                    Sometimes telling your story can be triggering. If you feel
                    like chatting to someone or need to seek help, please
                    contact 1800 RESPECT. If you’re in danger contact 000 or
                    112.
                  </BodyText>
                  <Chakra.Wrap justify="space-between" pb="100px">
                    <Chakra.WrapItem>
                      <NavbarLink
                        onClick={onClose}
                        fontSize="14px"
                        lineHeight="20px"
                        letterSpacing="-0.12px"
                        d="block"
                        color="#fff"
                        pb="28px"
                        to="/privacy/"
                      >
                        Privacy Policy
                      </NavbarLink>
                    </Chakra.WrapItem>
                    <Chakra.WrapItem>
                      <Chakra.Text
                        fontWeight="bold"
                        cursor="pointer"
                        fontSize="14px"
                        lineHeight="20px"
                        letterSpacing="-0.12px"
                        d="block"
                        color="#fff"
                        pb="28px"
                        to="/"
                      >
                        Subscribe to our newsletter
                      </Chakra.Text>
                    </Chakra.WrapItem>
                  </Chakra.Wrap>
                </Chakra.Flex>
              </Chakra.DrawerBody>
            </Chakra.DrawerContent>
          </Chakra.DrawerOverlay>
        </Chakra.Drawer>
      </Chakra.Flex>
    </>
  )
}

const MenuItem = ({label, url, childMenu}) => {

  if (childMenu.length === 0) {
    return (
        <NavbarLink to={url}>
          {label}
        </NavbarLink>
    )
  }

  return <MenuItemPopover label={label} url={url} childMenu={childMenu} />
}

const MenuItemPopover = ({label, url, childMenu}) => {

  const { isOpen, onOpen, onClose } = Chakra.useDisclosure()
  const [overrideOpen, setOverrideOpen] = React.useState(false)

  return (
      <Chakra.Tooltip
          isOpen={isOpen}
          onClose={debounce(overrideOpen ? ()=>{} : onClose, 400)}
          onOpen={onOpen}
          borderRadius="20px"
          arrowPadding={{ base: "25px", md: "35px" }}
          arrowSize="20px"
          placement="bottom"
          color="#3F3459"
          padding="0"
          fontWeight="500"
          bg="#A2D3C7"
          offset={[40, 25]}
          label={<Chakra.VStack onMouseLeave={()=>{ setOverrideOpen(false); onClose(); }} onMouseEnter={()=>{ setOverrideOpen(true); onOpen(); }} alignItems="flex-start" css={{pointerEvents: "auto"}} padding="20px" paddingRight="25px">
            {childMenu.map((item) => (
                <Chakra.Link fontSize="14px" _after={{display: "none"}} fontWeight="bold" color="#645389" _hover={{color: "#3F3459"}} transition="0.3s" as={Link} to={item.url}>
                  {item.label}
                </Chakra.Link>
            ))}
          </Chakra.VStack>}
          hasArrow
      >
        <Chakra.Link fontSize="14px" fontWeight="bold" color={isOpen ? "#3F3459" : "#645389"} transition="0.3s" as={Link} to={url}>
          {label}
        </Chakra.Link>
      </Chakra.Tooltip>
  )
}

export default Navbar
