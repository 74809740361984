module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-emotion-next-compat/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"shes-a-crowd","short_name":"She's A Crowd","start_url":"/","background_color":"#645389","theme_color":"#645389","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c4b73ec5f4a7091e44b3f0fc5aa765fc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"url":"https://shesacrowd2.wpengine.com/graphql","debug":{"preview":true,"graphql":{"printIntrospectionDiff":true,"showQueryVarsOnError":true,"writeQueriesToDisk":false,"showQueryOnError":false,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false},"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"schema":{"timeout":800000,"perPage":20,"requestConcurrency":5,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"presets":[{"options":{"type":{"Post":{"limit":60},"Page":{"limit":60},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true}}],"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"isResettingCSS":true,"isUsingColorMode":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KG5G9B9","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-189832078-1","head":false,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
