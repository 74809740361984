exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-wp-templates-content-node-archive-base-js": () => import("./../../../src/wp-templates/content-node/archive/base.js" /* webpackChunkName: "component---src-wp-templates-content-node-archive-base-js" */),
  "component---src-wp-templates-content-node-single-page-js": () => import("./../../../src/wp-templates/content-node/single/page.js" /* webpackChunkName: "component---src-wp-templates-content-node-single-page-js" */),
  "component---src-wp-templates-content-node-single-post-js": () => import("./../../../src/wp-templates/content-node/single/post.js" /* webpackChunkName: "component---src-wp-templates-content-node-single-post-js" */),
  "component---src-wp-templates-term-node-archive-category-js": () => import("./../../../src/wp-templates/term-node/archive/category.js" /* webpackChunkName: "component---src-wp-templates-term-node-archive-category-js" */),
  "component---src-wp-templates-term-node-archive-tag-js": () => import("./../../../src/wp-templates/term-node/archive/tag.js" /* webpackChunkName: "component---src-wp-templates-term-node-archive-tag-js" */)
}

